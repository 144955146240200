var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{attrs:{"variant":"danger","show":""}},[(_vm.error)?_c('div',{staticClass:"alert-body font-small-2"},[_c('b-card-text',[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon"}}),_c('small',[_vm._v(_vm._s(_vm.error))])],1)],1):_vm._e()]),_c('validation-observer',{ref:"giveForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.initializeOffering.apply(null, arguments)}}},[_c('div',[_c('h4',{staticClass:"mb-2"},[_vm._v(" Amount ")]),_c('b-form-group',{staticClass:"position-relative",attrs:{"label":"","label-for":"amount"}},[_c('span',{staticClass:"currency"},[_vm._v(_vm._s(_vm.currencySymbol))]),_c('validation-provider',{attrs:{"name":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.amount),expression:"amount"}],attrs:{"id":"amount","placeholder":"0.00","type":"number"},domProps:{"value":(_vm.amount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.amount=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',[_c('b-button-group',{staticClass:"mt-3"},[_c('b-button',{attrs:{"variant":_vm.gift_type === 'oneTime' ? 'warning' : 'outline-primary',"size":"lg"},on:{"click":function () { return _vm.gift_type = 'oneTime'; }}},[_vm._v(" Give one time ")]),_c('b-button',{attrs:{"variant":_vm.gift_type === 'recurring' ? 'warning' : 'outline-primary',"size":"lg"},on:{"click":function () { return _vm.gift_type = 'recurring'; }}},[_vm._v(" Set up recurring ")])],1),(_vm.gift_type === 'recurring')?_c('b-form-group',{staticClass:"mt-2 mb-2",attrs:{"label":"Frequency","label-for":"frequency"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(" Frequency"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('p',[_vm._v(" We will send you a link based on your preferred frequency to make payment ")]),_c('validation-provider',{attrs:{"name":"frequency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{staticClass:"mb-1 text-muted",attrs:{"name":"frequency","value":"every_day"},model:{value:(_vm.frequency),callback:function ($$v) {_vm.frequency=$$v},expression:"frequency"}},[_vm._v(" Every Day ")]),_c('b-form-radio',{staticClass:"mb-1 text-muted",attrs:{"name":"frequency","value":"every_week"},model:{value:(_vm.frequency),callback:function ($$v) {_vm.frequency=$$v},expression:"frequency"}},[_vm._v(" Every Week ")]),_c('b-form-radio',{staticClass:"mb-1 text-muted",attrs:{"name":"frequency","value":"every_2_weeks"},model:{value:(_vm.frequency),callback:function ($$v) {_vm.frequency=$$v},expression:"frequency"}},[_vm._v(" Every 2 Weeks ")]),_c('b-form-radio',{staticClass:"mb-1 text-muted",attrs:{"name":"frequency","value":"every_month"},model:{value:(_vm.frequency),callback:function ($$v) {_vm.frequency=$$v},expression:"frequency"}},[_vm._v(" Every Month ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1),_c('div',[(!_vm.church_profile_exists)?_c('h4',{staticClass:"mt-2 mb-2"},[_vm._v(" To ")]):_vm._e(),_c('b-row',[(!_vm.church_profile_exists)?_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Church Level","label-for":"church_level"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Church Level"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"church_level","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',[_vm._v("Select the church level to give.")]),_c('v-select',{attrs:{"id":"church_level","loading":_vm.loading,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.churchLevelOptions,"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.churchData.church_level),callback:function ($$v) {_vm.$set(_vm.churchData, "church_level", $$v)},expression:"churchData.church_level"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Select church level")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(!_vm.church_profile_exists && ['assembly', 'district', 'area'].includes(_vm.churchData.church_level))?_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Region","label-for":"region"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Region"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"region","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',[_vm._v("Select the region of the church")]),_c('v-select',{attrs:{"id":"region","loading":_vm.loading,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.churchRegionOptions,"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.churchData.church_region),callback:function ($$v) {_vm.$set(_vm.churchData, "church_region", $$v)},expression:"churchData.church_region"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Select church region")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e()],1),_c('b-row',{staticClass:"mt-1"},[(!_vm.church_profile_exists && ['assembly', 'district', 'area'].includes(_vm.churchData.church_level))?_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Area","label-for":"area"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Area"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"area","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"area","loading":_vm.loading,"clearable":false,"options":_vm.churchAreaOptions,"reduce":function (val) { return val.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.churchData.church_area),callback:function ($$v) {_vm.$set(_vm.churchData, "church_area", $$v)},expression:"churchData.church_area"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(!!_vm.churchData.church_region ? 'Select church area' : 'Select church region first'))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(!_vm.church_profile_exists && ['assembly', 'district'].includes(_vm.churchData.church_level))?_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"District","label-for":"region"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("District"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"district","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"district","loading":_vm.loading,"clearable":false,"options":_vm.churchDistrictOptions,"reduce":function (val) { return val.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.churchData.church_district),callback:function ($$v) {_vm.$set(_vm.churchData, "church_district", $$v)},expression:"churchData.church_district"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(!!_vm.churchData.church_area ? 'Select church district' : 'Select church area first'))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(!_vm.church_profile_exists && _vm.churchData.church_level === 'assembly')?_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Assembly","label-for":"assembly"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Assembly"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"assembly","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"assembly","loading":_vm.loading,"clearable":false,"options":_vm.churchAssemblyOptions,"reduce":function (val) { return val.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.churchData.church_assembly),callback:function ($$v) {_vm.$set(_vm.churchData, "church_assembly", $$v)},expression:"churchData.church_assembly"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(!!_vm.churchData.church_district ? 'Select church assembly' : 'Select church district first'))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e()],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Offering Type","label-for":"area"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Offering Type"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"area","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"area","loading":_vm.loading,"clearable":false,"options":_vm.offeringTypeOptions,"reduce":function (val) { return val.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.churchData.offering_type),callback:function ($$v) {_vm.$set(_vm.churchData, "offering_type", $$v)},expression:"churchData.offering_type"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(!_vm.churchData.church_level ? 'Select church level first' : 'Select offering type'))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('div',[_c('h4',{staticClass:"mt-2 mb-2"},[_vm._v(" Personal Information ")]),_c('b-row',{},[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"first_name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("First Name"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"custom-input"},[_c('span',{staticClass:"leading"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"20"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.first_name),expression:"userData.first_name"}],attrs:{"id":"first_name","placeholder":""},domProps:{"value":(_vm.userData.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData, "first_name", $event.target.value)}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Last Name","label-for":"last_name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Last Name"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"custom-input"},[_c('span',{staticClass:"leading"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"20"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.last_name),expression:"userData.last_name"}],attrs:{"id":"last_name","placeholder":"","height":"20px"},domProps:{"value":(_vm.userData.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData, "last_name", $event.target.value)}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Phone number","label-for":"email"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Phone number"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Phone Number","rules":"required|length:10","vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"custom-input"},[_c('span',{staticClass:"leading"},[_c('feather-icon',{attrs:{"icon":"PhoneIcon","size":"20"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.phone),expression:"userData.phone"}],attrs:{"id":"phone","placeholder":"","height":"20px"},domProps:{"value":(_vm.userData.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData, "phone", $event.target.value)}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('b-row',{staticClass:"pb-80"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Gender","label-for":"gender"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Gender"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{staticStyle:{"height":"2.7rem"},attrs:{"id":"gender","options":_vm.genderOptions},model:{value:(_vm.userData.gender),callback:function ($$v) {_vm.$set(_vm.userData, "gender", $$v)},expression:"userData.gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Email (optional)","label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","type":"email"},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"","label-for":"cover_fees"}},[_c('validation-provider',{attrs:{"name":"cover_fees","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{model:{value:(_vm.cover_fees),callback:function ($$v) {_vm.cover_fees=$$v},expression:"cover_fees"}},[_vm._v(" Cover Fees "),_c('span',[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"This will increase your gift slightly to cover the processing fees","icon":"InfoIcon","size":"20"}})],1)]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"fix-bottom"},[_c('div',{staticClass:"mt-2"},[_c('b-button',{staticClass:"py-1",staticStyle:{"font-size":"20px"},attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid || _vm.loading}},[_vm._v(" "+_vm._s(_vm.giveButtonTitle)+" ")]),_c('p',{staticClass:"text-center mt-2"},[_vm._v(" By proceeding signifies that you have agreed to the "),_c('b-link',{staticStyle:{"text-decoration":"underline"},attrs:{"to":{ name: 'terms' },"target":"_blank"}},[_vm._v(" Terms of Service ")]),_vm._v(" and our "),_c('b-link',{staticStyle:{"text-decoration":"underline"},attrs:{"to":{ name: 'privacy' },"target":"_blank"}},[_vm._v(" Privacy Policy.")])],1)],1)])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <b-alert variant="danger" show>
      <div v-if="error" class="alert-body font-small-2">
        <b-card-text>
          <feather-icon icon="AlertCircleIcon" />
          <small>{{ error }}</small>
        </b-card-text>
      </div>
    </b-alert>

    <validation-observer ref="giveForm" #default="{invalid}">
      <b-form @submit.prevent="initializeOffering">
        <div>
          <h4 class="mb-2">
            Amount
          </h4>

          <b-form-group label="" label-for="amount" class="position-relative">
            <span class="currency">{{ currencySymbol }}</span>
            <validation-provider #default="{ errors }" name="" rules="required">
              <input id="amount" v-model="amount" placeholder="0.00" type="number">
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>

        <div>
          <b-button-group class="mt-3">
            <b-button 
              :variant="gift_type === 'oneTime' ? 'warning' : 'outline-primary'" 
              size="lg"
              @click="() => gift_type = 'oneTime'"
            >
              Give one time
            </b-button>

            <b-button 
              :variant="gift_type === 'recurring' ? 'warning' : 'outline-primary'" 
              size="lg"
              @click="() => gift_type = 'recurring'"
            >
              Set up recurring
            </b-button>
          </b-button-group>

          <b-form-group v-if="gift_type === 'recurring'" label="Frequency" label-for="frequency" class="mt-2 mb-2">
            <template #label>
              <span>
                Frequency<span style="color: tomato">*</span>
              </span>
            </template>

            <p>
              We will send you a link based on your preferred frequency to make payment
            </p>

            <validation-provider #default="{ errors }" name="frequency" rules="required">
              <!-- <b-form-group v-slot="{ ariaDescribedby }" label=""> -->
                <b-form-radio v-model="frequency" name="frequency" value="every_day" class="mb-1 text-muted">
                  Every Day
                </b-form-radio>

                <b-form-radio v-model="frequency" name="frequency" value="every_week" class="mb-1 text-muted">
                  Every Week
                </b-form-radio>

                <b-form-radio v-model="frequency" name="frequency" value="every_2_weeks" class="mb-1 text-muted">
                  Every 2 Weeks
                </b-form-radio>

                <b-form-radio v-model="frequency" name="frequency" value="every_month" class="mb-1 text-muted">
                  Every Month
                </b-form-radio>
              <!-- </b-form-group> -->

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>

        <div>
          <h4 v-if="!church_profile_exists" class="mt-2 mb-2">
            To
          </h4>

          <b-row>
            <!-- <b-col cols="12" md="4">
              <b-form-group label="Currency" label-for="currency">
                <template #label>
                  <span>Currency<span style="color: tomato">*</span></span>
                </template>

                <validation-provider #default="{ errors }" name="currency" rules="required">
                  <p>Select your currency</p>
                  <v-select
                    id="currency"
                    v-model="currency"
                    :loading="loading"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="currencyOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                  >
                    <span slot="no-options">Select currency</span>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->

          <b-col v-if="!church_profile_exists" cols="12" md="4">
              <b-form-group label="Church Level" label-for="church_level">
                <template #label>
                  <span>Church Level<span style="color: tomato">*</span></span>
                </template>

                <validation-provider #default="{ errors }" name="church_level" rules="required">
                  <p>Select the church level to give.</p>
                  <v-select
                    id="church_level"
                    v-model="churchData.church_level"
                    :loading="loading"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="churchLevelOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                  >
                    <span slot="no-options">Select church level</span>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col v-if="!church_profile_exists && ['assembly', 'district', 'area'].includes(churchData.church_level)" cols="12" md="4">
              <b-form-group label="Region" label-for="region">
                <template #label>
                  <span>Region<span style="color: tomato">*</span></span>
                </template>

                <validation-provider #default="{ errors }" name="region" rules="required">
                  <p>Select the region of the church</p>
                  <v-select
                    id="region"
                    v-model="churchData.church_region"
                    :loading="loading"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="churchRegionOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                  >
                    <span slot="no-options">Select church region</span>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          
          <b-row class="mt-1">
            <b-col v-if="!church_profile_exists && ['assembly', 'district', 'area'].includes(churchData.church_level)" cols="12" md="4">
              <b-form-group label="Area" label-for="area">
                <template #label>
                  <span>Area<span style="color: tomato">*</span></span>
                </template>

                <validation-provider #default="{ errors }" name="area" rules="required">
                  <v-select
                    id="area"
                    v-model="churchData.church_area"
                    :loading="loading"             
                    :clearable="false"
                    :options="churchAreaOptions"
                    :reduce="val => val.value"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  >
                    <span slot="no-options">{{ !!churchData.church_region ? 'Select church area' : 'Select church region first' }}</span>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col v-if="!church_profile_exists && ['assembly', 'district'].includes(churchData.church_level)" cols="12" md="4">
              <b-form-group label="District" label-for="region">
                <template #label>
                  <span>District<span style="color: tomato">*</span></span>
                </template>

                <validation-provider #default="{ errors }" name="district" rules="required">
                  <v-select
                    id="district"
                    v-model="churchData.church_district"
                    :loading="loading"             
                    :clearable="false"
                    :options="churchDistrictOptions"
                    :reduce="val => val.value"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  >
                    <span slot="no-options">{{ !!churchData.church_area ? 'Select church district' : 'Select church area first' }}</span>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col v-if="!church_profile_exists && churchData.church_level === 'assembly'" cols="12" md="4">
              <b-form-group label="Assembly" label-for="assembly">
                <template #label>
                  <span>Assembly<span style="color: tomato">*</span></span>
                </template>

                <validation-provider #default="{ errors }" name="assembly" rules="required">
                  <v-select
                    id="assembly"
                    v-model="churchData.church_assembly"
                    :loading="loading"             
                    :clearable="false"
                    :options="churchAssemblyOptions"
                    :reduce="val => val.value"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  >
                    <span slot="no-options">{{ !!churchData.church_district ? 'Select church assembly' : 'Select church district first' }}</span>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col cols="12" md="4">
              <b-form-group label="Offering Type" label-for="area">
                <template #label>
                  <span>Offering Type<span style="color: tomato">*</span></span>
                </template>

                <validation-provider #default="{ errors }" name="area" rules="required">
                  <v-select
                    id="area"
                    v-model="churchData.offering_type"
                    :loading="loading"             
                    :clearable="false"
                    :options="offeringTypeOptions"
                    :reduce="val => val.value"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  >
                    <span slot="no-options">{{ !churchData.church_level ? 'Select church level first' : 'Select offering type' }}</span>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

        </div>

        <div>
          <h4 class="mt-2 mb-2">
            Personal Information
          </h4>

          <b-row class="">
            <b-col cols="12" md="4">
              <b-form-group label="First Name" label-for="first_name">
                <template #label>
                  <span>First Name<span style="color: tomato">*</span></span>
                </template>

                <validation-provider #default="{ errors }" name="First Name" rules="required">
                  <div class="custom-input">
                    <span class="leading">
                      <feather-icon icon="UserIcon" size="20" />
                    </span>
                    <input id="first_name" v-model="userData.first_name" placeholder="">
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label="Last Name" label-for="last_name">
                <template #label>
                  <span>Last Name<span style="color: tomato">*</span></span>
                </template>

                <validation-provider #default="{ errors }" name="Last Name" rules="required">
                  <div class="custom-input">
                    <span class="leading">
                      <feather-icon icon="UserIcon" size="20" />
                    </span>
                    <input id="last_name" v-model="userData.last_name" placeholder="" height="20px">
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label="Phone number" label-for="email">
                <template #label>
                  <span>Phone number<span style="color: tomato">*</span></span>
                </template>

                <validation-provider #default="{ errors }" name="Phone Number" rules="required|length:10" vid="phone">
                  <div class="custom-input">
                    <span class="leading">
                      <feather-icon icon="PhoneIcon" size="20" />
                    </span>
                    <input id="phone" v-model="userData.phone" placeholder="" height="20px">
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <b-row class="pb-80">
          <b-col cols="12" md="6">
            <b-form-group label="Gender" label-for="gender" class="">
              <template #label>
                <span>Gender<span style="color: tomato">*</span></span>
              </template>

              <validation-provider #default="{ errors }" name="gender" rules="required">
                <b-form-select id="gender" v-model="userData.gender" :options="genderOptions" style="height: 2.7rem" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group label="Email (optional)" label-for="email">
              <b-form-input
                id="email"
                v-model="userData.email"
                type="email"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="" label-for="cover_fees" class="mt-2">
              <validation-provider #default="{ errors }" name="cover_fees" rules="">
                <b-form-checkbox v-model="cover_fees">
                  Cover Fees <span><feather-icon v-b-tooltip.hover title="This will increase your gift slightly to cover the processing fees" icon="InfoIcon" size="20"></feather-icon> </span>
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="fix-bottom">
          <div class="mt-2">
            <b-button class="py-1" type="submit" variant="primary" block :disabled="invalid || loading" style="font-size: 20px;">
              {{ giveButtonTitle }}
            </b-button>

            <p class="text-center mt-2">
              By proceeding signifies that you have agreed
              to the <b-link :to="{ name: 'terms' }" target="_blank" style="text-decoration: underline">
                Terms of Service
              </b-link>
              and our <b-link :to="{ name: 'privacy' }" target="_blank" style="text-decoration: underline">
              Privacy Policy.</b-link>
            </p>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { required } from '@validations'

import vSelect from 'vue-select'
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BAlert,
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormRadioGroup,
  BFormRadio,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BOverlay,
  BRow,
  VBTooltip,
  BCard,
  BButtonGroup
} from 'bootstrap-vue'
import { get } from "lodash"

/* eslint-disable global-require */
import {
  ValidationObserver,
  ValidationProvider
} from 'vee-validate'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    vSelect,
    AuthWrapper,
    BOverlay,
    BCard,
    BAlert,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BButtonGroup,
    BFormRadioGroup,
    BFormRadio,
    // BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    callbackUrl: {
      required: false,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,

      error: '',
      gift_type: "oneTime",
      frequency: 'every_week',

      amount: '',
      currency: 'GHS',

      offering_type: '',
      cover_fees: false,

      church_profile_exists: false,

      userData: {
        first_name: '',
        last_name: '',
        phone: '',
        gender: '',
        email: '',
      },

      churchData: {
        church_level: '',
        church_region: '',
        church_area: '',
        church_district: '',
        church_assembly: '',
        offering_type: '',
      },
      currencySymbols: {
        GHS: 'GH₵',
        NGN: '₦',
        ZAR: 'R',
        KES: 'KSh',
        USD: "$"
      },

      genderOptions: [
        {
          value: "female",
          text: "Female"
        },
        {
          value: "male",
          text: "Male"
        }
      ],
      currencyOptions: [
        {
          value: "GHS",
          label: "GHS"
        },
        {
          value: "NGN",
          label: "NGN"
        },
        {
          value: "ZAR",
          label: "ZAR"
        },
        {
          value: "KES",
          label: "KES"
        },
        {
          value: "USD",
          label: "USD"
        }
      ],
      
      churchAreaOptions: [],
      churchRegionOptions: [],
      offeringTypeOptions: [],
      churchDistrictOptions: [],
      churchAssemblyOptions: [],

      // validation rules
      required
    }
  },
  computed: {
    currencySymbol() {
      const currency = this.currencySymbols[this.currency];
      return currency
    },
    giveButtonTitle() {
      return `Give ${this.currencySymbol}${this.amount > 0 ? this.formatMoney(this.amount) : '0.00'}`
    },
  },
  watch: {
    "churchData.church_level": {
      handler(changed){
        if (changed){
          this.fetchOfferingTypes();
        }
      },
      immediate: true
    },
    "churchData.church_region": {
      handler(changed){
        if (changed){
          this.fetchChurchAreas();
        }
      },
      immediate: true
    },
    "churchData.church_area": {
      handler(changed){
        if (changed){
          this.fetchChurchDistrict();
        }
      },
      immediate: true
    },
    "churchData.church_district": {
      handler(changed){
        if (changed){
          this.fetchChurchAssemblies();
        }
      },
      immediate: true
    },
    loading: {
      handler(v){
        this.$emit('loading', v)
      }
    }
  },
  async created() {
    const { trxref, reference, gift_type, church_id } = this.$route.query;
    if (trxref || reference){
      await this.verifyPayment(reference)
    }

    if (gift_type){
      this.gift_type = gift_type
    }

    await this.fetchOfferingTypes();

    if (church_id){
      await this.verifyChurchProfile(church_id)
    } else {
      await this.fetchChurchRegions();
    }
    

    if (this.currentUser){
      const { first_name, last_name, phone, email, gender } = this.currentUser;
      this.userData = {
        first_name: first_name || '',
        last_name: last_name || '',
        phone: phone || '',
        gender: gender || '',
        email: email || '',
      }
    }
  },
  methods: {
    async initializeOffering() {
      try {
        this.loading = true
        this.error = "";

        const success = await this.$refs.giveForm.validate();
        if (!success) {
          throw new Error("Please check and make sure all fields are valid");
        }

        const result = await this.$swal({
          title: 'Confirm Payment?',
          text: `Do you want to ${this.giveButtonTitle}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        const { church_id } = this.$route.query;

        const request_data = {
          amount: this.amount,
          currency: this.currency,
          gift_type: this.gift_type,
          frequency: this.frequency,
          member_data: this.userData,
          cover_fees: this.cover_fees,
          callback_url: this.callbackUrl,
          offering_type: this.churchData.offering_type,
          church_id
        };

        if (!this.church_profile_exists){
          request_data.church_data = this.churchData;
        }

        const response = await this.useJwt().sharedService.initializeOffering(request_data);
        const response_data = response.data.data;
        const { authorization_url } = response_data;
        location.href = authorization_url
      } catch (error) {
        console.log(error);
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.error = error_message
        this.loading = false;
      }
    },
    async verifyChurchProfile(church_id){
      try {
        this.loading = true
        this.error = "";

        if (church_id){
          const response = await this.useJwt().sharedService.verifyId(church_id);
          const { status, name: churchName } = response.data.data;
          this.church_profile_exists = status;
          this.$emit('changeChurchName', churchName)
        }

        if (!this.church_profile_exists){
          await this.fetchChurchRegions();
          await this.fetchOfferingTypes();
        }
      } catch (error) {
        console.log(error);
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.error = error_message
      } finally {
        this.loading = false;
      }
    },
    async verifyPayment(reference) {
      try {
        this.loading = true

        const response = await this.useJwt().sharedService.verifyPayment(reference);
        const response_data = response.data.data;
        const { status } = response_data;

        if (status){
          await this.$swal({
            title: 'Success',
            text: `We have successfully acknowledged receipt of your payment. God richly bless you.`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
          .then(() => {
            this.$router.replace({}).catch(() => {});
          })
        } else {
          await this.$swal({
            title: 'Failed',
            text: `Payment failed. If this was an error. Please contact support`,
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
          .then(() => {
            this.$router.replace({}).catch(() => {});
          })
        }
      } catch (error) {
        console.log(error);
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.error = error_message
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchRegions() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchApprovedChurchData('region', {});
        const { data } = request.data;
        this.churchRegionOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        this.churchData.church_region = '';
        this.churchData.church_area = '';
        this.churchData.church_district = '';
        this.churchData.church_assembly = '';
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchAreas() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchApprovedChurchData('area', {
          church_region: this.churchData.church_region
        });
        const { data } = request.data;
        this.churchAreaOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        this.churchData.church_area = '';
        this.churchData.church_district = '';
        this.churchData.church_assembly = '';
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchDistrict() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchApprovedChurchData('district', {
          church_region: this.churchData.church_region,
          church_area: this.churchData.church_area,
        });
        const { data } = request.data;
        this.churchDistrictOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        this.churchData.church_district = '';
        this.churchData.church_assembly = '';
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchAssemblies() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchApprovedChurchData('assembly', {
          church_region: this.churchData.church_region,
          church_area: this.churchData.church_area,
          church_district: this.churchData.church_district,
        });
        const { data } = request.data;
        this.churchAssemblyOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        this.churchData.church_assembly = '';
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchOfferingTypes() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchOfferingTypes({
          page: 1,
          limit: 1000,
          church_level: this.churchData.church_level
        });
        const { data } = request.data;
        this.offeringTypeOptions = data.map(opt => ({
          label: opt.title,
          value: opt.id
        }));

        this.churchData.offering_type = '';
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>
